import request from "@/utils/request";

export function warehouseOption(params) {
  return request({ url: `/warehouses/options/`, method: "get", params });
}

export function categoryOption(params) {
  return request({ url: `/category/options/`, method: "get", params });
}

export function unitOption(params) {
  return request({ url: `/units/options/`, method: "get", params });
}

export function reservoirAreaOption(params) {
  return request({ url: `/reservoir_areas/options/`, method: "get", params });
}

export function orderTypeOption(params) {
  return request({ url: `/order_types/options/`, method: "get", params });
}

export function materialOption(params) {
  return request({ url: `/materials/options/`, method: "get", params });
}

export function batchOption(params) {
  return request({ url: `/batchs/options/`, method: "get", params });
}

export function inventoryOption(params) {
  return request({ url: `/inventories/options/`, method: "get", params });
}

export function deliveryCustomOption(params) {
  return request({ url: `/delivery_clients/options/`, method: "get", params });
}
